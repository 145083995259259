<template>
    <div class="ui justified container">
        <div class="ui fluid labeled icon menu">
            <router-link to="/" class="item">
                <i class="home icon"></i>
                Home
            </router-link>
            <router-link class="item" to="/shopping">
                <i class="cart icon"></i>
                Ichaufä
            </router-link>
            <router-link class="item" to="/notes">
                <i class="sticky note outline icon"></i>
                Notizä
            </router-link>
            <router-link class="item" to="/todos">
                <i class="check icon"></i>
                Todo
            </router-link>
            <router-link class="item" to="/admin">
                <i class="cog icon"></i>
                Admin
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header'
}
</script>