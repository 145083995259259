<template>
    <div :id="'edit-note-' + note.id" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Bearbeite
        </div>
        <div class="content">
            <div class="description">
                <input class="title-field" type="text" v-model="title" placeholder="Titel">
                <editor v-model="content"/>
            </div>
            <div class="actions">
                <button class="ui black deny button">
                    Abbreche
                </button>
                <button @click="saveNote()" class="ui positive right labeled icon button">
                    Speichere
                    <i class="checkmark icon"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="content">
        <span v-if="note.shared!=0" class="right floated" :data-tooltip="'Teilt mit: ' + note.shared">
            <i class="share alternate icon"></i>
        </span>
        <span v-if="note.shared==0" class="right floated" data-tooltip="Privati Notiz">
            <i class="lock icon"></i>
        </span>

        <div class="header" v-html="title"></div>
        <div class="description wysiwyg-content" v-html="content">
        </div>
        <div class="extra content">
            <div class="right floated small">
                <span class="ui small text">{{ note.lastsavedby }}, {{ note.changed }}</span>
            </div>
        </div>
    </div>
    <div v-if="note.trash!=1" class="ui bottom attached two buttons">
        <button @click="edit(note.id)" class="ui button">
            <i class="edit icon"></i>
            Bearbeite
        </button>
        <button @click="trash(note.id)" class="ui button red">
            <i class="trash icon"></i>
            Löschä
        </button>
    </div>

    <button v-if="note.trash==1" @click="untrash(note.id)" class="ui bottom attached button">
        <i class="edit icon"></i>
        Wiederherstelle
    </button>
</template>

<script>
import Editor from './Editor.vue'


export default {
    name: 'Note',
    components: {
        Editor
    },
    computed: {},
    props: {
        note: Object
    },
    data() {
        return {
            content: '',
            title: ''
        }
    },
    created() {
    },
    mounted() {
        this.content = this.note.notes;
        this.title = this.note.title;
    },
    methods: {
        edit(id) {
            $('#edit-note-' + id).modal('show');
        },
        async untrash(id) {
            const res = await fetch('https://app.bentele.me/api/notes', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({untrashid: id}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.notes = data.notes;
            }
        },
        async trash(id) {
            const res = await fetch('https://app.bentele.me/api/notes', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({trashid: id}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.notes = data.notes;
            }
        },
        async saveNote() {
            console.log('saving...')
            const res = await fetch('https://app.bentele.me/api/notes', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({id: this.note.id, notes: this.content, title: this.title}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.notes = data.notes;
            }
        },
    }
}
</script>

<style scoped>
.card .description {
    max-height: 110px;
    overflow-y: hidden;
    position: relative;
    height: 100%;
}

.card .description:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 30px;
    top: 80px;
    background-image: linear-gradient(to bottom, transparent, white);
}

.title-field {
    width: 100%;
    border: 1px solid #5e5e5e;
    box-shadow: none;
    outline: none;
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 15px;
}

.ui.cards > .card > .content {
    padding-bottom: 0.2em;
}

</style>
<style>
.wysiwyg-content ul li p {
    margin-bottom: 0 !important;
}

.wysiwyg-content ul {
    margin-bottom: 0 !important;
}

.wysiwyg-content .ProseMirror {
    min-height: 150px;
}

</style>