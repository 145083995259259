<template>
    <h1 class="ui header">Salü {{ user ? user.usernicename : 'du unbekannti Person' }}</h1>

    <Login v-show="!user" :msg="msg" @login="login"/>
    <div v-show="user">
        <router-link to="/shopping" class="ui button">Poschtiliste</router-link>
        <router-link to="/todos" class="ui button">Todos</router-link>
    </div>
</template>

<script>
import Login from './Login'

export default {
    name: 'Home',
    components: {
        Login
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    data() {
        return {
            msg: '',
        }
    },
    async created() {
        if (!this.$store.state.user) {
            await this.login({login: '1'});
        }
    },
    methods: {
        async login(login) {
            const res = await fetch('https://app.bentele.me/api', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(login).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.user = data.user;
            } else {
                this.msg = data.msg;
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
