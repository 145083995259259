<template>
    <div id="app">
        <Header/>
        <div class="ui justified container">
            <router-view/>
        </div>
    </div>
</template>

<script>
import Header from './components/Header'

export default {
    name: 'App',
    data() {
        return {}
    },
    components: {
        Header
    },
    mounted() {
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.ui.justified.container {
    margin-top: 30px;
}
</style>
