import {createRouter, createWebHistory} from 'vue-router'
import Welcome from '../views/Welcome'
import Shopping from '../views/Shopping';
import Notes from "../views/Notes";
import ToDos from "../views/ToDos";
import Admin from "../views/Admin";

const routes = [
    {
        path: '/',
        name: 'Welcome',
        component: Welcome,
    },
    {
        path: '/shopping',
        name: 'Shopping',
        component: Shopping,
    },
    {
        path: '/notes',
        name: 'Notes',
        component: Notes,
    },
    {
        path: '/todos',
        name: 'ToDos',
        component: ToDos,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active"
})

export default router