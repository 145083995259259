<template>

    <button v-if="trash" @click="toggleTrash()" class="ui button right floated">Zrugg</button>
    <button v-if="!trash" @click="toggleTrash()" class="ui button right floated">
        <i class="trash icon"></i>(<span v-html="trashCount"></span>)
    </button>
    <button @click="$refs.addNote.newNote()" class="ui button right floated"><i class="sticky note outline icon"></i> Neu</button>
    <h1>Notizä <span v-if="trash">- Chübl</span></h1>
    <div class="ui cards">
        <div v-for="note in notes" class="card">
            <note :note="note"></note>
        </div>
    </div>
    <add-note ref="addNote"></add-note>
</template>

<script>
import Note from "../components/Note";
import AddNote from "../components/AddNote";

export default {
    name: 'Notes',
    components: {
        Note,
        AddNote
    },
    data() {
        return {
            trash: false,
        }
    },
    computed: {
        notes() {
            if (this.$store.state.notes.length) {
                if (this.trash) {
                    return this.$store.state.notes.filter(item => item.trash == 1);
                } else {
                    return this.$store.state.notes.filter(item => item.trash != 1);
                }
            }
            return [];
        },
        trashCount() {
            return this.$store.state.notes.filter(item => item.trash == 1).length;
        }
    },
    async created() {
        if (!this.$store.state.notes.length) {
            await this.fetchItems();
        }
    },

    methods: {
        async fetchItems() {
            const res = await fetch('https://app.bentele.me/api/notes', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            const data = await res.json();
            if (data && data.status === 'success') {
                this.$store.state.notes = data.notes;
            }
        },
        toggleTrash() {
            this.trash = !this.trash;
        }
    }
}
</script>
