<template>
    <div v-if="itemsToDo && itemsToDo.length">
        <p>Mä sött no das machä:</p>
        <div class="ui segments">
            <div :key="item.id" v-for="item in itemsToDo" class="ui segment">
                <div class="ui checkbox checked">
                    <input :id="'item-to-buy-' + item.id" type="checkbox" tabindex="0" class="hidden">
                    <label :for="'item-to-buy-'+item.id" @click="check(item.id)">
                        <b>{{ item.itemname }}</b><br>
                        <span class="ui small text">Am: {{ item.created }}</span><br>
                    </label>
                </div>
                <button @click="deleteItem(item.id)" class="ui icon button red right floated"><i class="trash icon"></i></button>
            </div>
        </div>

        <p>Da isch scho gmacht:</p>
        <div class="ui segments">
            <div :key="item.id" v-for="item in itemsDone" class="ui segment">
                <div class="ui checkbox checked">
                    <input :id="'item-bought-' + item.id" type="checkbox" tabindex="0" class="hidden" checked>
                    <label :for="'item-to-buy-'+item.id" @click="uncheck(item.id)">
                        <b>{{ item.itemname }}</b><br>
                        <span class="ui small text">Am: {{ item.created }}</span><br>
                        <span class="ui small text">Vo: {{ item.enteredby }}
                            <span v-if="item.team && item.team.length >0"> für: {{ item.team }}</span>
                            <span v-else>für: mich</span>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="ui segment">
        <div class="ui active inverted dimmer">
            <div class="ui text loader">Moment bitte...</div>
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
</template>

<script>

export default {
    name: 'TodoItems',
    components: {},
    computed: {
        itemsToDo() {
            return this.$store.state.toDoItems.filter(item => item.checked == 0)
        },
        itemsDone() {
            return this.$store.state.toDoItems.filter(item => item.checked == 1)
        }
    },
    data() {
        return {}
    },
    async created() {
        if (!this.$store.state.toDoItems.length) {
            await this.fetchItems();
        }

    },
    mounted() {

    },
    methods: {
        async fetchItems() {
            const res = await fetch('https://app.bentele.me/api/todos', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.toDoItems = data.toDoItems;
            }
        },
        async check(id) {
            const res = await fetch('https://app.bentele.me/api/todos', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({checkid: id}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.toDoItems = data.toDoItems;
            }
        },
        async uncheck(id) {
            const res = await fetch('https://app.bentele.me/api/todos', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({uncheckid: id}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.toDoItems = data.toDoItems;
            }
        },
        async deleteItem(id) {
            const res = await fetch('https://app.bentele.me/api/todos', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({deleteid: id}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.toDoItems = data.toDoItems;
            }
        }

    }
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

.ui.checkbox.checked label p {
    margin-bottom: 0;
}

a {
    color: #42b983;
}
</style>
