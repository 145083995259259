<template>
    <h1>ToDos</h1>
    <add-todo-item></add-todo-item>
    <todo-items></todo-items>
</template>

<script>
import TodoItems from "../components/TodoItems";
import AddTodoItem from "../components/AddTodoItem";

export default {

    name: 'ToDos',
    components: {
        TodoItems,
        AddTodoItem
    }
}
</script>
