<template>
    <button class="ui button right floated" @click="newUser()"><i class="sticky note outline icon"></i> Neuer User</button>
    <table class="ui compact celled  table">
        <thead>
        <tr>
            <th>Name</th>
            <th>Username</th>
            <th>E-Mail</th>
            <th>Rolle</th>
            <th>Team</th>
            <th>Aktiv</th>
            <th>Changed</th>
            <th>Created</th>
            <th>Lastlogin</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users" @click="editUser(user)">
            <td>{{ user.usernicename }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.role }}</td>
            <td>{{ user.team }}</td>
            <td>{{ user.active==1?'ja':'nope' }}</td>
            <td>{{ user.changed }}</td>
            <td>{{ user.created }}</td>
            <td>{{ user.lastlogin }}</td>
        </tr>
        </tbody>
    </table>

    <div id="edit-user" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Bearbeite
        </div>
        <div class="content">
            <div class="description">
                <form class="ui form">
                    <div class="field">
                        <label for="usernicename">usernicename</label>
                        <input id="usernicename" type="text" v-model="user.usernicename" placeholder="usernicename">
                    </div>
                    <div class="field">
                        <label for="username">username</label>
                        <input id="username" type="text" v-model="user.username" placeholder="username">
                    </div>
                    <div class="field">
                        <label for="email">email</label>
                        <input id="email" type="email" v-model="user.email" placeholder="email">
                    </div>
                    <div class="field">
                        <label for="role">role</label>
                        <input id="role" type="text" v-model="user.role" placeholder="role">
                    </div>
                    <div class="field">
                        <label for="team">team</label>
                        <input id="team" type="text" v-model="user.team" placeholder="role">
                    </div>
                    <div class="field">
                        <div class="ui checkbox">
                            <input id="active" type="checkbox" true-value="1" false-value="0" v-model="user.active" tabindex="0" class="hidden">
                            <label for="active">active</label>
                        </div>
                    </div>
                    <div class="field">
                        <label for="password">password</label>
                        <input id="password" type="text" v-model="user.password" placeholder="password">
                    </div>
                </form>
            </div>
            <div class="actions">
                <button class="ui red button" @click="trashUser()">Löschen</button>
                <button class="ui black deny button">
                    Abbreche
                </button>
                <button @click="saveUser()" class="ui positive right labeled icon button">
                    Speichere
                    <i class="checkmark icon"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Admin',
    components: {},
    data() {
        return {
            user: {}
        }
    },
    computed: {
        users() {
            if (this.$store.state.users.length) {
                return this.$store.state.users.filter(item => item.trash != 1);
            } else {
                return [];
            }
        }
    },
    async created() {
        if (!this.$store.state.users.length) {
            await this.fetchItems();
        }
    },

    methods: {
        async fetchItems() {
            const res = await fetch('https://app.bentele.me/api/users', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            const data = await res.json();
            if (data && data.status === 'success') {
                this.$store.state.users = data.users;
            }
        },
        newUser() {
            this.user = {newuser: true};
            $('#edit-user').modal('show');
        },
        editUser(user) {
            this.user = user;
            $('#edit-user').modal('show');
        },
        async trashUser() {
            var user = this.user;
            const res = await fetch('https://app.bentele.me/api/users', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({trashid: user.id}).toString(),
            })
            const data = await res.json();
            if (data && data.status === 'success') {
                this.$store.state.users = data.users;
            }
            $('#edit-user').modal('hide');
        },
        async saveUser() {
            var user = this.user;
            const res = await fetch('https://app.bentele.me/api/users', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(user).toString(),
            })
            const data = await res.json();
            if (data && data.status === 'success') {
                this.$store.state.users = data.users;
            }
        }
    }
}
</script>
