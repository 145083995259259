<template>
    <form @submit="addItem" class="ui aligned basic">
        <div class="ui left icon action input">
            <i class="shopping basket icon"></i>
            <input type="text" v-model="itemname" placeholder="z.B. Öpfel">
            <button class="ui submit button green">Speichorä</button>
        </div>
    </form>
</template>

<script>
export default {
    name: 'AddShoppingItem',
    components: {},
    computed: {},
    data() {
        return {
            itemname: ''
        }
    },
    async created() {
    },
    mounted() {

    },
    methods: {
        async addItem(e) {
            e.preventDefault();
            const res = await fetch('https://app.bentele.me/api/shopping', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({itemname: this.itemname, team: ''}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.shoppingItems = data.shoppingItems;
            }
        },
    }
}
</script>

<style scoped>
.ui.aligned.basic {
    margin-bottom: 1em;
}
</style>
