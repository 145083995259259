<template>
    <div :id="'add-note'" class="ui modal">
        <i class="close icon"></i>
        <div class="header">
            Neui Notiz
        </div>
        <div class="content">
            <div class="description">
                <input class="title-field" type="text" v-model="title" placeholder="Titel">
                <editor v-model="content"/>
            </div>
            <div class="actions">
                <button class="ui black deny button">
                    Abbreche
                </button>
                <button @click="saveNote()" class="ui positive right labeled icon button">
                    Speichere
                    <i class="checkmark icon"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from './Editor.vue'


export default {
    name: 'AddNote',
    components: {
        Editor
    },
    computed: {},
    props: {
        note: Object
    },
    data() {
        return {
            content: '',
            title: ''
        }
    },
    created() {
    },
    mounted() {
        this.content = '';
        this.title = '';
    },
    methods: {
        newNote() {
            $('#add-note').modal('show');
        },
        async saveNote() {
            const res = await fetch('https://app.bentele.me/api/notes', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({newnote: this.content, title: this.title}).toString(),
            })
            const data = await res.json();

            if (data && data.status === 'success') {
                this.$store.state.notes = data.notes;
            }
        },
    }
}
</script>

<style scoped>
.card .description {
    max-height: 110px;
    overflow-y: hidden;
    position: relative;
    height: 100%;
}

.card .description:after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 30px;
    top: 80px;
    background-image: linear-gradient(to bottom, transparent, white);
}

.title-field {
    width: 100%;
    border: 1px solid #5e5e5e;
    box-shadow: none;
    outline: none;
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 15px;
}

.ui.cards > .card > .content {
    padding-bottom: 0.2em;
}

</style>