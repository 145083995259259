import 'fomantic-ui/dist/semantic.min.css'
import 'fomantic-ui/dist/semantic.min.js'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createStore} from 'vuex'

// Create a new store instance.
const store = createStore({
    state() {
        return {
            user: false,
            shoppingItems: [],
            toDoItems: [],
            notes: [],
            users:[],
        }
    },
})

createApp(App)
    .use(router)
    .use(store)
    .mount('#app')