<template>
    <div class="ui doubling two column centered grid">
        <div class="column">
            <div v-show="msg" class="ui error message">
                {{ msg }}
            </div>

            <form @submit="login" class="ui form">
                <div class="field">
                    <label>Benutzernamä</label>
                    <input type="text" v-model="username" name="username" placeholder="andrea">
                </div>
                <div class="field">
                    <label>Passwort</label>
                    <input type="password" v-model="password" name="password" placeholder="********">
                </div>
                <div class="field">
                    <div class="ui checkbox">
                        <input id="remember" type="checkbox" v-model="remember" name="remember" tabindex="0">
                        <label for="remember">Merkdo mi</label>
                    </div>
                </div>
                <button class="ui button" type="submit">Amelde</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    props: {
        msg: String
    },
    data() {
        return {
            username: '',
            password: '',
            remember: false,
            loggedIn: false,
        }
    },
    methods: {
        login(e) {
            e.preventDefault()

            const login = {
                username: this.username,
                password: this.password,
                remember: this.remember,
            }

            this.$emit('login', login);

        }
    }
}
</script>